/* -----------------------------------------------------------------------------
 *
 * Components: Return Visitor Modal
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Common
 *
 * This file is just a clearing-house.
 * Use this file to pull in common utilties into CSS Modules
 *
 * ----------------------------------------------------------------------------- */

/* Fonts */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Fonts
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Object Sans
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Regular.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Medium.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Bold.woff2' );
}

/* -----------------------------------------------------------------------------
 * Bandwidth
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'bandwidth';
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/bandwidth/BandwidthDisplay_Rg.woff2' );
}

/* Media Queries */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Media Queries
 *
 * ----------------------------------------------------------------------------- */

@custom-media --xsmall( min-width: 320px );

/* Admin */

/* Mixins */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Icomoon
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Utilities
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Grid
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Mixins: Backgrounds
 *
 * ----------------------------------------------------------------------------- */

/* Color utils */

.ReturnVisitorModal_u-color-blue__m46VT {
  color: var( --color-electric-blue );
}

.ReturnVisitorModal_u-color-red__NrKkP {
  color: var( --color-torch-red );
}

.ReturnVisitorModal_u-color-midnight__YnBy8 {
  color: var( --color-midnight-express );
}

.ReturnVisitorModal_u-color-white__7INRr {
  color: var( --color-white );
}

.ReturnVisitorModal_u-theme-white__WasoA {
  background-color: var( --color-white );
  color: var( --color-black );
}

.ReturnVisitorModal_u-theme-light__HLOF1 {
  background-color: var( --color-zircon );
}

.ReturnVisitorModal_u-theme-dark__D3OyU {
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

.ReturnVisitorModal_u-theme-blue__VYh18 {
  background-color: var( --color-electric-blue );
}

.ReturnVisitorModal_u-theme-red__DUtHl {
  background-color: var( --color-torch-red );
  color: var( --color-white );
}

.ReturnVisitorModal_u-theme-black___JhGC {
  background-color: var( --color-black );
  color: var( --color-white );
}

/* Visibility utils (taken from Foundation) */

@media print, screen and (max-width: 39.99875em) {
  .ReturnVisitorModal_hide-for-small-only__iucsq {
    display: none !important;
  }
}

@media screen and (max-width: 0), screen and (min-width: 40em) {
  .ReturnVisitorModal_show-for-small-only__Cv1at {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .ReturnVisitorModal_hide-for-medium__Y_ROT {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em) {
  .ReturnVisitorModal_show-for-medium__GL35v {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .ReturnVisitorModal_hide-for-medium-only__12gFo {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .ReturnVisitorModal_show-for-medium-only__g7dtL {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .ReturnVisitorModal_hide-for-large__hd83S {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em) {
  .ReturnVisitorModal_show-for-large__Cv5yM {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .ReturnVisitorModal_hide-for-large-only__DrE7i {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .ReturnVisitorModal_show-for-large-only__F3PLw {
    display: none !important;
  }
}

.ReturnVisitorModal_component__oFzsM {
  padding: 0;
  text-align: center;
}

/* -----------------------------------------------------------------------------
 * Modal Container
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__modalContainer__k1YCl {
  max-width: calc( var( --grid-width-default ) - (var( --grid-gutter ) * 2) );
  margin-top: 60px;
  position: relative;
  padding: 0;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__modalContainer__k1YCl {
    max-width: var( --grid-width-default )
}
  }

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__modalContainer__k1YCl {
    margin-top: 0
}
  }

/* -----------------------------------------------------------------------------
 * Content Wrapper
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__contentWrapper___qcj9 {
  padding: 40px 24px;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__contentWrapper___qcj9 {
    padding: 80px 45px
}
  }

@media ( min-width: 1280px ) {

.ReturnVisitorModal_component__contentWrapper___qcj9 {
    padding: 80px 100px
}
  }

/* -----------------------------------------------------------------------------
 * Header
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__header__dScgu {
  display: block;
  margin-bottom: 32px;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__header__dScgu {
    margin-bottom: 56px
}
  }

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__header__dScgu {
    margin-bottom: 70px
}
  }

/* -----------------------------------------------------------------------------
 * Close
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__close__zwEWT {
  position: absolute;
  right: var( --grid-gutter-half );
  font-size: 1.3125rem;
  margin-top: 5px;
}

/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__title__yMMWh {
  margin-bottom: 24px;
  padding: 0;
  color: var(--color-torch-red);
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__title__yMMWh {
    margin-bottom: 24px
}
  }

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__title__yMMWh {
    margin-bottom: 32px
}
  }

/* -----------------------------------------------------------------------------
 * Subtitle
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__subtitle__pRR1O {
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.22;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__subtitle__pRR1O {
    margin: 0 auto
}
  }

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__subtitle__pRR1O {
    max-width: 70%
}
  }

/* -----------------------------------------------------------------------------
 * Body
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__body__Se8tA {
  margin: 0;
}

/* -----------------------------------------------------------------------------
 * Blocks
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__blocks__LLRrp {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__blocks__LLRrp {
    flex-flow: row;
    align-items: stretch
}
  }

/* -----------------------------------------------------------------------------
 * Block
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__block__p7LRe {
  display: flex;
  flex-flow: column;
  margin-bottom: 32px;
  color: var( --color-midnight-express );
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__block__p7LRe {
    display: block;
    flex: 1;
    margin-right: 24px;
    margin-bottom: 0
}
  }

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__block__p7LRe {
    margin-right: 80px
}
  }

.ReturnVisitorModal_component__blocks__LLRrp .ReturnVisitorModal_component__block__p7LRe:last-of-type {
  margin-bottom: 0;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__blocks__LLRrp .ReturnVisitorModal_component__block__p7LRe:last-of-type {
    margin-right: 0
}
  }

/* -----------------------------------------------------------------------------
 * Block Title
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__blockTitle__GpBn9 {
  padding: 0;
  margin-bottom: 16px;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__blockTitle__GpBn9 {
    margin-bottom: 24px
}
  }

.ReturnVisitorModal_component__blockTitle__GpBn9 button {
  padding: 0;
}

/* -----------------------------------------------------------------------------
 * Block Content
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__blockContent__v_SCL {
  margin-bottom: 16px;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__blockContent__v_SCL {
    margin-bottom: 40px;
    min-height: 83px
}
  }

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__blockContent__v_SCL {
    min-height: 61px
}
  }

@media ( min-width: 1280px ) {

.ReturnVisitorModal_component__blockContent__v_SCL {
    min-height: 81px
}
  }

@media ( min-width: 1440px ) {

.ReturnVisitorModal_component__blockContent__v_SCL {
    min-height: 63px
}
  }

/* -----------------------------------------------------------------------------
 * Block Button
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__blockBtn__T2XFG {
  display: block;
  font-size: 0.875rem;
  line-height: 1.57;
  margin: 0;
}

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__blockBtn__T2XFG {
    display: inline-block
}
  }

a.ReturnVisitorModal_component__blockBtn__T2XFG[ href^='tel' ] {
  color: var( --color-white );
}

/* Spinning loader animation from https://loading.io/css/ */
.Loader_loader__jyVuH {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.Loader_loader__jyVuH div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid var( --color-midnight-express );
  border-radius: 50%;
  animation: Loader_loader__jyVuH 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var( --color-midnight-express ) transparent transparent transparent;
}
.Loader_loader__jyVuH div:nth-child(1) {
  animation-delay: -0.45s;
}
.Loader_loader__jyVuH div:nth-child(2) {
  animation-delay: -0.3s;
}
.Loader_loader__jyVuH div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes Loader_loader__jyVuH {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ResumeQuoteModal_component__modalContainer__r0t0u {
  width: var( --grid-width-default );
  padding: 0;
  border-radius: 24px;
}

.ResumeQuoteModal_component__close__4iMsZ {
  position: absolute;
  right: 0;
  margin: 26px 27px 0 0 ;
}

.ResumeQuoteModal_component__close__4iMsZ::before {
  font-size: 24px;
  font-weight: 700;
}

.ResumeQuoteModal_component__contentWrapper__UaCdM {
  text-align: center;
  padding: 80px 0;
}

@media ( max-width: 767px ) {

.ResumeQuoteModal_component__contentWrapper__UaCdM {
    padding-left: 24px;
    padding-right: 24px
}
  }

.ResumeQuoteModal_component__headerIcon__ctFDQ {
  margin-bottom: 20px;
  color: var( --color-torch-red );
  font-size: 40px;
}

.ResumeQuoteModal_component__heading__r828h {
  margin: 0 0 16px 0;
  padding: 0;
}

@media ( min-width: 768px ) {

.ResumeQuoteModal_component__heading__r828h {
    font-size: 1.875rem;
    line-height: 1.13
}
  }

.ResumeQuoteModal_component__heading__r828h .tooltip-btn {
    font-size: 60%;
  }

@media ( min-width: 768px ) {

.ResumeQuoteModal_component__heading__r828h .tooltip-btn {
      font-size: 40%
  }
    }

.ResumeQuoteModal_component__subheading__kpfZA {
  font-weight: 500;
}

.ResumeQuoteModal_component__subheading__kpfZA .tooltip-btn {
    font-size: 80%;
  }

.ResumeQuoteModal_component__addressWrapper__C5XSE {
  margin: 42px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

@media ( max-width: 767px ) {

.ResumeQuoteModal_component__addressWrapper__C5XSE {
    flex-wrap: wrap;
    margin: 34px 0
}
  }

.ResumeQuoteModal_component__icon___vzX7 {
  font-size: 24px;
  margin-right: 8px;
}

.ResumeQuoteModal_component__address__89csr {
  margin: 0 0 0 8px;
  text-transform: uppercase;
}

@media ( max-width: 767px ) {

.ResumeQuoteModal_component__address__89csr {
    margin: 10px 0 0
}
  }

.ResumeQuoteModal_component__buttonWrapper__OmFdz {
  width: 265px;
  margin: 0 auto;
}
.tippy-box[data-animation=fade][data-state=hidden]{opacity:0}[data-tippy-root]{max-width:calc(100vw - 10px)}.tippy-box{position:relative;background-color:#333;color:#fff;border-radius:4px;font-size:14px;line-height:1.4;white-space:normal;outline:0;transition-property:transform,visibility,opacity}.tippy-box[data-placement^=top]>.tippy-arrow{bottom:0}.tippy-box[data-placement^=top]>.tippy-arrow:before{bottom:-7px;left:0;border-width:8px 8px 0;border-top-color:initial;transform-origin:center top}.tippy-box[data-placement^=bottom]>.tippy-arrow{top:0}.tippy-box[data-placement^=bottom]>.tippy-arrow:before{top:-7px;left:0;border-width:0 8px 8px;border-bottom-color:initial;transform-origin:center bottom}.tippy-box[data-placement^=left]>.tippy-arrow{right:0}.tippy-box[data-placement^=left]>.tippy-arrow:before{border-width:8px 0 8px 8px;border-left-color:initial;right:-7px;transform-origin:center left}.tippy-box[data-placement^=right]>.tippy-arrow{left:0}.tippy-box[data-placement^=right]>.tippy-arrow:before{left:-7px;border-width:8px 8px 8px 0;border-right-color:initial;transform-origin:center right}.tippy-box[data-inertia][data-state=visible]{transition-timing-function:cubic-bezier(.54,1.5,.38,1.11)}.tippy-arrow{width:16px;height:16px;color:#333}.tippy-arrow:before{content:"";position:absolute;border-color:transparent;border-style:solid}.tippy-content{position:relative;padding:5px 9px;z-index:1}
/* -----------------------------------------------------------------------------
 *
 * Components: Hero Address Check
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Common
 *
 * This file is just a clearing-house.
 * Use this file to pull in common utilties into CSS Modules
 *
 * ----------------------------------------------------------------------------- */

/* Fonts */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Fonts
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Object Sans
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Regular.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Medium.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Bold.woff2' );
}

/* -----------------------------------------------------------------------------
 * Bandwidth
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'bandwidth';
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/bandwidth/BandwidthDisplay_Rg.woff2' );
}

/* Media Queries */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Media Queries
 *
 * ----------------------------------------------------------------------------- */

@custom-media --xsmall( min-width: 320px );

/* Admin */

/* Mixins */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Icomoon
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Utilities
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Grid
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Mixins: Backgrounds
 *
 * ----------------------------------------------------------------------------- */

/* Color utils */

.HeroAddressCheck5G_u-color-blue__AWU6u {
  color: var( --color-electric-blue );
}

.HeroAddressCheck5G_u-color-red__c10cJ {
  color: var( --color-torch-red );
}

.HeroAddressCheck5G_u-color-midnight__ar5zJ {
  color: var( --color-midnight-express );
}

.HeroAddressCheck5G_u-color-white__bbcr1 {
  color: var( --color-white );
}

.HeroAddressCheck5G_u-theme-white__mS64g {
  background-color: var( --color-white );
  color: var( --color-black );
}

.HeroAddressCheck5G_u-theme-light__YGBAk {
  background-color: var( --color-zircon );
}

.HeroAddressCheck5G_u-theme-dark__IO7TM {
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

.HeroAddressCheck5G_u-theme-blue__vOt0i {
  background-color: var( --color-electric-blue );
}

.HeroAddressCheck5G_u-theme-red__ugvZi {
  background-color: var( --color-torch-red );
  color: var( --color-white );
}

.HeroAddressCheck5G_u-theme-black__ps_jx {
  background-color: var( --color-black );
  color: var( --color-white );
}

/* Visibility utils (taken from Foundation) */

@media print, screen and (max-width: 39.99875em) {
  .HeroAddressCheck5G_hide-for-small-only__1l6oL {
    display: none !important;
  }
}

@media screen and (max-width: 0), screen and (min-width: 40em) {
  .HeroAddressCheck5G_show-for-small-only__THe8p {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .HeroAddressCheck5G_hide-for-medium__QzhgL {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em) {
  .HeroAddressCheck5G_show-for-medium__yf_wp {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .HeroAddressCheck5G_hide-for-medium-only__fknLk {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .HeroAddressCheck5G_show-for-medium-only__20ahv {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .HeroAddressCheck5G_hide-for-large__F_YrR {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em) {
  .HeroAddressCheck5G_show-for-large__eigZz {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .HeroAddressCheck5G_hide-for-large-only__m9Ti_ {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .HeroAddressCheck5G_show-for-large-only__WvIfx {
    display: none !important;
  }
}

@media ( min-width: 768px ) {

.HeroAddressCheck5G_component__8t6FI {
    background-position-x: 100%
}
  }

/* override */

.HeroAddressCheck5G_component__8t6FI .HeroAddressCheck5G_component__wrapper__IGSpM {
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.HeroAddressCheck5G_component__8t6FI .HeroAddressCheck5G_component__layout__dPz7J {
  padding-right: 0;
  padding-left: 0;
}

.HeroAddressCheck5G_component__ctaContainer__CwuwT {
  max-width: 800px;
  margin-left: 35px;
  padding-right: 35px;
}

@media ( min-width: 768px ) {

.HeroAddressCheck5G_component__ctaContainer__CwuwT {
    margin-left: 50px
}
  }

@media ( min-width: 1280px ) {

.HeroAddressCheck5G_component__ctaContainer__CwuwT {
    margin-left: 165px
}
  }

@media (min-width: 1600px) {

.HeroAddressCheck5G_component__ctaContainer__CwuwT {
    margin-left: 345px
}
  }

.HeroAddressCheck5G_component__mobileBackground__WGnCR {
  width: 376px;
  margin-right: auto;
  margin-left: auto;
  transform: none;
}

/* -----------------------------------------------------------------------------
 *
 * Component: Split Section
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Common
 *
 * This file is just a clearing-house.
 * Use this file to pull in common utilties into CSS Modules
 *
 * ----------------------------------------------------------------------------- */

/* Fonts */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Fonts
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Object Sans
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Regular.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Medium.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Bold.woff2' );
}

/* -----------------------------------------------------------------------------
 * Bandwidth
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'bandwidth';
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/bandwidth/BandwidthDisplay_Rg.woff2' );
}

/* Media Queries */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Media Queries
 *
 * ----------------------------------------------------------------------------- */

@custom-media --xsmall( min-width: 320px );

/* Admin */

/* Mixins */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Icomoon
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Utilities
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Grid
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Mixins: Backgrounds
 *
 * ----------------------------------------------------------------------------- */

/* Color utils */

.SplitSection_u-color-blue__QaME_ {
  color: var( --color-electric-blue );
}

.SplitSection_u-color-red__Xklgz {
  color: var( --color-torch-red );
}

.SplitSection_u-color-midnight__AagTM {
  color: var( --color-midnight-express );
}

.SplitSection_u-color-white__9Iyou {
  color: var( --color-white );
}

.SplitSection_u-theme-white__t2XDa {
  background-color: var( --color-white );
  color: var( --color-black );
}

.SplitSection_u-theme-light__RTZMq {
  background-color: var( --color-zircon );
}

.SplitSection_u-theme-dark__IBh72 {
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

.SplitSection_u-theme-blue__P9Win {
  background-color: var( --color-electric-blue );
}

.SplitSection_u-theme-red__n_mhS {
  background-color: var( --color-torch-red );
  color: var( --color-white );
}

.SplitSection_u-theme-black__Pyt6n {
  background-color: var( --color-black );
  color: var( --color-white );
}

/* Visibility utils (taken from Foundation) */

@media print, screen and (max-width: 39.99875em) {
  .SplitSection_hide-for-small-only__FYxeA {
    display: none !important;
  }
}

@media screen and (max-width: 0), screen and (min-width: 40em) {
  .SplitSection_show-for-small-only__S54Ea {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .SplitSection_hide-for-medium__JUxJ7 {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em) {
  .SplitSection_show-for-medium__ZdHvg {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .SplitSection_hide-for-medium-only__1_Y9H {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .SplitSection_show-for-medium-only__cM9rO {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .SplitSection_hide-for-large__4eZJV {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em) {
  .SplitSection_show-for-large__fuo_1 {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .SplitSection_hide-for-large-only__cGQ0f {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .SplitSection_show-for-large-only__51d_9 {
    display: none !important;
  }
}

.SplitSection_component__b5vuh {
  display: flex;
  position: relative;
  flex-flow: column-reverse;
  padding: 0;
  overflow-x: hidden;
}

.SplitSection_componentMobileReverse__xP8ZI {
  flex-flow: column;
}

/* -----------------------------------------------------------------------------
 * Content
 * ----------------------------------------------------------------------------- */

.SplitSection_component__content__f47SY {
  padding: 60px 0;
}

@media ( min-width: 1024px ) {

.SplitSection_component__content__f47SY {
    padding: 80px 0;
    padding-left: 32.5px
}
  }

@media ( min-width: 1024px ) {

.SplitSection_component__contentReverse__AqBWL {
    padding-right: 32.5px;
    padding-left: 0
}
  }

.SplitSection_component__copyContainer__BlSRs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.SplitSection_component__copy__AFVYu {
  margin-bottom: 0;
}

/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */

.SplitSection_component__title__QrobQ {
  margin-bottom: 15px;
  padding: 0;
  font-weight: 700;
}

@media ( min-width: 768px ) {

.SplitSection_component__title__QrobQ span {
    font-size: 2.1875rem;
    line-height: 1.43
}
  }

/* -----------------------------------------------------------------------------
 * Subtitle
 * ----------------------------------------------------------------------------- */

.SplitSection_component__subtitle__wUbFF {
  margin-bottom: 30px;
}

/* -----------------------------------------------------------------------------
 * Item heading legal
 * ----------------------------------------------------------------------------- */

.SplitSection_component__itemHeading__7QDfl sup {
  position: relative;
  top: -10px;
  font-size: 50%;
}

/* -----------------------------------------------------------------------------
 * List
 * ----------------------------------------------------------------------------- */

.SplitSection_component__list__ppjC8 {
  margin-bottom: 30px;
  padding: 0;
  list-style-type: none;
  line-height: 1.25;
}

.SplitSection_component__list__ppjC8 li {
  position: relative;
  margin-bottom: 20px;
}

/* -----------------------------------------------------------------------------
 * Icon
 * ----------------------------------------------------------------------------- */

.SplitSection_component__icon__92t2g {
  margin-right: 10px;
  color: var( --color-primary );
  font-size: 1.0625rem;
}

.SplitSection_component__icon__red__VXIPT,
.SplitSection_component__icon__dark__YaSzw {
  color: var( --color-electric-blue );
}

/* -----------------------------------------------------------------------------
 * Legal
 * ----------------------------------------------------------------------------- */

.SplitSection_component__legal__B_UXo {
  margin-bottom: 40px;
}

/* -----------------------------------------------------------------------------
 * Legal
 * ----------------------------------------------------------------------------- */

@media ( min-width: 1024px ) {

.SplitSection_component__buttonStyle__8rGSN {
    width: 100%
}
  }

@media ( min-width: 1440px ) {

.SplitSection_component__buttonStyle__8rGSN {
    width: 48%
}
  }

/* -----------------------------------------------------------------------------
 * Background Wrap
 * ----------------------------------------------------------------------------- */

.SplitSection_component__backgroundWrap__4ms7G {
  position: relative;
  width: 100%;
  height: 100%;
}

@media ( min-width: 768px ) {

.SplitSection_component__backgroundWrap__4ms7G {
    display: block;
    width: 50vw;
}
  }

@media ( max-width: 767px ) {
    .SplitSection_component__backgroundWrap__4ms7G img {
      object-fit: cover !important;
      object-position: center !important;
    }
  }

/* @media (--hd) {
    max-width: 100%;
  } */

.SplitSection_component__backgroundWrap__4ms7G img {
    object-fit: cover;
    object-position: center;
  }

@media ( min-width: 768px ) {

.SplitSection_component__backgroundWrap__4ms7G {
    position: absolute;
    right: var( --grid-gutter );
}
  }

.SplitSection_component__backgroundWrap__4ms7G {

  right: 0;
  overflow: hidden;
}

.SplitSection_component__backgroundWrapReverse__GBZts {
  left: 0;
}

.SplitSection_component__backgroundWrapContain__2X_QG img {
  object-fit: contain !important;
}

/*
.component__backgroundWrapMobile {
  display: block;
}

.component__backgroundWrap img {
  @media (--xlarge) {
    object-fit: contain;
  }
} */

/* -----------------------------------------------------------------------------
 * Background
 * ----------------------------------------------------------------------------- */

.SplitSection_component__background__yTso_ {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.SplitSection_component__background_img__E_xGT {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

/* -----------------------------------------------------------------------------
 * Background Mobile
 * ----------------------------------------------------------------------------- */

.SplitSection_component__backgroundMobile__ootsG {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 230px;
}

@media ( min-width: 576px ) {

.SplitSection_component__backgroundMobile__ootsG {
    height: 414px
}
  }

@media ( min-width: 768px ) {

.SplitSection_component__backgroundMobile__ootsG {
    display: none
}
  }

/* -----------------------------------------------------------------------------
 *
 * Components: FloatingPanel
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Common
 *
 * This file is just a clearing-house.
 * Use this file to pull in common utilties into CSS Modules
 *
 * ----------------------------------------------------------------------------- */

/* Fonts */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Fonts
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Object Sans
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Regular.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Medium.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Bold.woff2' );
}

/* -----------------------------------------------------------------------------
 * Bandwidth
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'bandwidth';
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src: url( 'https://fastly-tundra.frontier.redventures.io/migration/fonts/bandwidth/BandwidthDisplay_Rg.woff2' );
}

/* Media Queries */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Media Queries
 *
 * ----------------------------------------------------------------------------- */

@custom-media --xsmall( min-width: 320px );

/* Admin */

/* Mixins */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Icomoon
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Utilities
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Grid
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Mixins: Backgrounds
 *
 * ----------------------------------------------------------------------------- */

/* Color utils */

.FloatingPanel_u-color-blue__CC7mu {
  color: var( --color-electric-blue );
}

.FloatingPanel_u-color-red__DdNJm {
  color: var( --color-torch-red );
}

.FloatingPanel_u-color-midnight__WsPsp {
  color: var( --color-midnight-express );
}

.FloatingPanel_u-color-white__dGvJp {
  color: var( --color-white );
}

.FloatingPanel_u-theme-white__26UvE {
  background-color: var( --color-white );
  color: var( --color-black );
}

.FloatingPanel_u-theme-light__l6gL8 {
  background-color: var( --color-zircon );
}

.FloatingPanel_u-theme-dark__f7IBX {
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

.FloatingPanel_u-theme-blue__MU_6S {
  background-color: var( --color-electric-blue );
}

.FloatingPanel_u-theme-red__jsQkh {
  background-color: var( --color-torch-red );
  color: var( --color-white );
}

.FloatingPanel_u-theme-black__pEu5z {
  background-color: var( --color-black );
  color: var( --color-white );
}

/* Visibility utils (taken from Foundation) */

@media print, screen and (max-width: 39.99875em) {
  .FloatingPanel_hide-for-small-only__irfT7 {
    display: none !important;
  }
}

@media screen and (max-width: 0), screen and (min-width: 40em) {
  .FloatingPanel_show-for-small-only___oPG8 {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .FloatingPanel_hide-for-medium__oe0QT {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em) {
  .FloatingPanel_show-for-medium__Zpk07 {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .FloatingPanel_hide-for-medium-only__pHKL9 {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .FloatingPanel_show-for-medium-only__s_9Lx {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .FloatingPanel_hide-for-large__m4eon {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em) {
  .FloatingPanel_show-for-large__WS_3K {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .FloatingPanel_hide-for-large-only__9kgbN {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .FloatingPanel_show-for-large-only__CC4fb {
    display: none !important;
  }
}

.FloatingPanel_component__KBT0G {
  padding-top: 30px;
  padding-bottom: 22px;
  background-color: var( --color-white );
  background-repeat: no-repeat;
  background-position: bottom 38px right 0;
  background-size: 100% 44%;
}

@media ( min-width: 768px ) {

.FloatingPanel_component__KBT0G {
    background-position: bottom 88px right 0;
    background-size: 100% 46%
}
  }

@media ( min-width: 1024px ) {

.FloatingPanel_component__KBT0G {
    background-position: bottom 93px right 0
}
  }

@media ( min-width: 1440px ) {

.FloatingPanel_component__KBT0G {
    padding-bottom: 40px;
    background-position: bottom -8px right 0;
    background-size: 100vw 112%
}
  }

/* -----------------------------------------------------------------------------
 * Row
 * ----------------------------------------------------------------------------- */

@media ( min-width: 768px ) {

.FloatingPanel_row__9V1bM {
    justify-content: center;
    margin-right: auto;
    margin-left: auto
}
  }

@media ( min-width: 1440px ) {

.FloatingPanel_row__9V1bM {
    align-items: center;
    justify-content: space-between
}
  }

/* -----------------------------------------------------------------------------
 * Panel
 * ----------------------------------------------------------------------------- */

.FloatingPanel_panel__5wTxF {
  width: 100%;
  margin-bottom: 6px;
  padding: 40px 24px;
  border-radius: 32px;
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

@media ( min-width: 768px ) {

.FloatingPanel_panel__5wTxF {
    padding: 48px
}
  }

@media ( min-width: 1440px ) {

.FloatingPanel_panel__5wTxF {
    max-width: 600px;
    margin-bottom: 0;
    padding: 88px 48px
}
  }

/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */

.FloatingPanel_title__kzuwF {
  padding-top: 0;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.13;
}

@media ( min-width: 768px ) {

.FloatingPanel_title__kzuwF {
    margin-bottom: 28px
}
  }

@media ( min-width: 1440px ) {

.FloatingPanel_title__kzuwF {
    margin-bottom: 8px;
    font-size: 3.5rem;
    line-height: 1.07
}
  }

/* -----------------------------------------------------------------------------
 * Subtitle
 * ----------------------------------------------------------------------------- */

.FloatingPanel_subtitle__MXUHN {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.22;
}

@media ( min-width: 768px ) {

.FloatingPanel_subtitle__MXUHN {
    margin-bottom: 28px
}
  }

/* -----------------------------------------------------------------------------
 * UL
 * ----------------------------------------------------------------------------- */

.FloatingPanel_ul__CsWJL {
  list-style-type: none;
}

/* -----------------------------------------------------------------------------
 * LI
 * ----------------------------------------------------------------------------- */

.FloatingPanel_li__fdAoL {
  position: relative;
  margin-bottom: 16px;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.25;
}

.FloatingPanel_li__fdAoL:last-of-type {
  margin-bottom: 40px;
}

.FloatingPanel_li__fdAoL::before {
  font-family: 'icomoon' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  speak: none;

  content: var( --icon-check-short );
  position: absolute;
  top: 1px;
  margin-left: -30px;
  color: var( --color-electric-blue );
  font-size: 1.125rem;
}

/* -----------------------------------------------------------------------------
 * Image Container
 * ----------------------------------------------------------------------------- */

@media ( min-width: 1440px ) {

.FloatingPanel_imageContainer__oFvfI {
    transform: translateX( 100px )
}
  }

